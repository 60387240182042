import { _ } from "vue-underscore";
import Vue from 'vue';
import Multiselect from 'vue-multiselect'

export default {
  data: () => ({
    landingAirlines: {
      id: 0,
      name: "",
      airline_id: "",
      url_slug: "",
      title: "",
      description: "",
      page_title: "",
      head_section: "",
      meta_keywords: "",
      body_section: "",
      logoImage: "",
      logo_url: "",
      airline_rating: "",
    },
    loading: false,
    webUrl: process.env.VUE_APP_WEB_URL,
    apiUrl: process.env.VUE_APP_API_URL,
    error: "",
    airlines: [],
    selectedCity: [],
    images: [],
    files: [],
    cityList: [],
    selectedCountry: [],
    backUrl: "/landing-airlines"
  }),
  components: {
    Multiselect
  },
  methods: {
    customLabel({ city, city_code }) {
      return `${city} – ${city_code}`
    },
    // Reset Form
    resetForm() {
      this.$refs.observer.reset();
      this.landingAirlines = {
        id: 0,
        name: "",
        airline_id: "",
        url_slug: "",
        title: "",
        description: "",
        page_title: "",
        head_section: "",
        body_section: "",
        logoImage: "",
        logo_url: "",
        airline_rating: "",
      };
    },
    // Add and Update Function call to submit button 

    validatelandingAirlines() {
      const imageCount = (this.landingAirlines.landing_airlines_image && this.landingAirlines.landing_airlines_image.length > 0) ? this.landingAirlines.landing_airlines_image.length : 0
      // Check if the total number of images is less than 4
      if (this.images.length + imageCount < 4) {
        Vue.$toast.error("Multiple Images more than 4");
        return;
      }
      // Set the loading state to true
      this.loading = true;
      // Set a timeout to set the loading state to false after 10 seconds
      setTimeout(() => {
        this.loading = false;
      }, 10000);
      // Perform the update if the landingAirlines id is greater than 0, otherwise perform the add operation
      this.landingAirlines.id > 0 ? this.update() : this.add();
    },


    // Selecte File 
    onFileChange(e) {
      var selectedFiles = e.target.files;
      // Loop through the selected files and add them to the 'images' array
      for (let i = 0; i < selectedFiles.length; i++) {
        this.images.push(selectedFiles[i]);
      }
      // Loop through the 'images' array and set the source of each image element
      for (let i = 0; i < this.images.length; i++) {
        let reader = new FileReader(); // Instantiate a new FileReader
        // Add an event listener to handle the 'load' event
        reader.addEventListener('load', function () {
          this.$refs['image' + parseInt(i)][0].src = reader.result;
        }.bind(this), false);
        reader.readAsDataURL(this.images[i]); // Read the file as a data URL
      }
    },

    //Remove Image in Storage

    removeImage(id) {
      let _vm = this;
      // Display a confirmation dialog to the user
      this.$dialog
        .confirm("Are you sure you want to delete this record?")
        .then(function () {
          // If the user confirms the deletion, send a DELETE request to the server
          _vm.axios
            .delete("landing-airline-image-delete/" + id)
            .then(function () {
              // If the deletion is successful, update the detail
              _vm.getDetail();
            })
            .catch(function (error) {
            });
        })
        .catch(function (error) {
          // If the user cancels the deletion, log a message to the console
        });
    },

    //Update Airline City Deatils and Multiple Images
    update() {
      let _vm = this;
      const cities = [];
      this.selectedCity.forEach(element => {
        cities.push(element.city_code)
      });
      _vm.landingAirlines.cities_to_fly = cities.join(",");
      let fd = new FormData();

      if (_vm.landingAirlines.logoImage) {
        fd.append("logoImage", _vm.landingAirlines.logoImage);
      }
      if (this.images && this.images.length > 0) {
        this.images.forEach(element => {
          fd.append("multipleImages[]", element);
        });
      }
      // Append properties from landingAirlines object to FormData
      Object.keys(_vm.landingAirlines).forEach(key => {
        fd.append(key, _vm.landingAirlines[key]);
      });
      fd.append("_method", "PATCH");
      this.axios
        .post("/landing-airline/" + this.landingAirlines.id, fd, {
          ...this.landingAirlines
        })
        .then(function (response) {
          _vm.landingAirlines = response.data.data;
          _vm.$router.push(_vm.backUrl);
        })
        .catch(function () {
          // Error handling
        });
    },


    //Get Image On Update Time
    selectImage(event) {
      let _vm = this;
      var input = event.target;
      // Check if files were selected
      if (input.files && input.files[0]) {
        _vm.landingAirlines.logoImage = input.files[0];
        var reader = new FileReader();
        // Set up a callback for when the file is loaded
        reader.onload = function (e) {
          // Update the background image of the imagePreview element
          $("#imagePreview").css(
            "background-image",
            "url(" + e.target.result + ")"
          );
          // Hide and fade in the imagePreview element
          $("#imagePreview").hide();
          $("#imagePreview").fadeIn(650);
        };
        // Read the file as a data URL
        reader.readAsDataURL(input.files[0]);
      }
    },


    // Store Landing City Deatils and Multiple Images
    add() {
      let _vm = this;
      const cities = [];
      this.selectedCity.forEach(element => {
        cities.push(element.city_code)
      });
      _vm.landingAirlines.cities_to_fly = cities.join(",");
      let fd = new FormData();
      // Add comment explaining the condition
      if (_vm.landingAirlines.logoImage) {
        fd.append("logoImage", _vm.landingAirlines.logoImage);
      }
      // Add comment explaining the multiple image store
      if (this.images && this.images.length > 0) {
        this.images.forEach(element => {
          fd.append("multipleImages[]", element);
        });
      }
      Object.keys(_vm.landingAirlines).forEach(key => {
        fd.append(key, _vm.landingAirlines[key]);
      });
      this.axios.post("/landing-airline", fd).then(function (response) {
        _vm.$router.push(_vm.backUrl);
      });
    },

    getQueryString() {
      let queryString = "?search=";
      return queryString;
    },

    //Fetch Data On Update
    getDetail() {
      let _vm = this;
      // Make a GET request to retrieve landing airline details
      this.axios
        .get("/landing-airline/" + this.$route.params.id)
        .then(function (response) {
          _vm.landingAirlines = response.data.data;
          _vm.selectedCity = [];
          // Split the cities to fly string and add selected cities to the list
          const cities = _vm.landingAirlines.cities_to_fly.split(",");
          _vm.cityList.forEach(element => {
            if (cities.indexOf(element.city_code) > -1) {
              _vm.selectedCity.push({ 'city': element.city, 'city_code': element.city_code });
            }
          });
          // Display the full image if available
          if (
            _vm.landingAirlines.full_image_url &&
            _vm.landingAirlines.full_image_url.length > 0
          ) {
            $("#imagePreview").css(
              "background-image",
              "url(" +
              _vm.landingAirlines.full_image_url +
              "?date=" +
              Date.now() +
              ")"
            );
            $("#imagePreview").hide();
            $("#imagePreview").fadeIn(650);
          }
        })
        .catch(function () { });
    },


    //Get Airlines On Airlines Module
    getAirline() {
      let _vm = this;
      // Making a GET request to "/airline-list" endpoint
      this.axios
        .get("/airline-list", _vm.airlines)
        .then(function (response) {
          _vm.airlines = response.data.data;
          // Checking if the route parameter "id" is greater than 0
          if (parseInt(_vm.$route.params.id) > 0) {
            // If the "id" is greater than 0, call the getDetail() function
            _vm.getDetail();
          }
        })
        .catch(function () {
          // Error handling
        });
    },


    //Get Cities On Airlines Module
    getCity() {
      let _vm = this;
      // Send a GET request to retrieve the list of cities by city code
      this.axios
        .get("/city-by-city-code-list", _vm.cityList)
        .then(function (response) {
          _vm.cityList = response.data.data;
          // Check if the ID parameter in the route is a positive integer
          if (parseInt(_vm.$route.params.id) > 0) {
            _vm.getDetail(); // If ID is valid, retrieve the city details
          }
        })
        .catch(function () { });
    },
    updateUrl(event){
      if(event.code === 'Space'){
        if(this.landingAirlines.url_slug.length>1 && this.landingAirlines.url_slug[this.landingAirlines.url_slug.length-2]==='-'){
          this.landingAirlines.url_slug =  event.target.value.replace(/\s{1,}/g, '');
        }else{
         this.landingAirlines.url_slug =  event.target.value.replace(/\s{1,}/g, '-');
        }
      }

    },
  },
  mounted() {
    if (this.$route.params.id > 0) {
      this.getDetail();
    }
   
    this.getAirline();
    this.getCity();
  }
};
